import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, } from '@angular/router';
import { PageService } from '../../_services/index';
import { CustomPage } from '../../_models/index';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-custom-pages',
    templateUrl: './custom-pages.component.html',
    styleUrls: ['./custom-pages.component.scss'],
    standalone: false
})
export class CustomPagesComponent implements OnInit, OnDestroy {
    selectedPage: CustomPage = null;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor(
        private title: Title,
        private pageService: PageService,
        private route: ActivatedRoute) {
        this.route.params.subscribe(params => {
            const slug = params.slug;
            this.pageService.getCustomPage(slug).pipe(
                takeUntil(this.ngUnsubscribe))
                .subscribe(page => {
                    this.selectedPage = page;
                    this.title.setTitle('Solent Wholesale | ' + page.name);
                });
        });

    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }


    ngOnInit() {
    }

}
