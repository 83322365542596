import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { faBusinessTime } from '@fortawesome/free-solid-svg-icons';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.scss'],
    standalone: false
})
export class AboutUsComponent implements OnInit {
    faCircle = faCircle;
    faTruck = faTruck;
    faShoppingCart  = faShoppingCart;
    faBusinessTime = faBusinessTime;
    faTrophy = faTrophy;
    carosuelImages = [
        {
            url: 'https://swc-website.s3-eu-west-1.amazonaws.com/static_images/building.png',
            description: 'Our warehouse in Chichester, W Sussex'
        },
        {
            url: 'https://swc-website.s3-eu-west-1.amazonaws.com/static_images/racking.png',
            description: 'We have over 150 stock ranges available for next day delivery'
        },
        {
            url: 'https://swc-website.s3-eu-west-1.amazonaws.com/static_images/fleet.png',
            description: 'We own our own delivery fleet to ensure perfect deliveries'
        },
        {
            url: 'https://swc-website.s3-eu-west-1.amazonaws.com/static_images/machine.png',
            description: 'We have a state of the art automatic cutting machine'
        },
        {
            url: 'https://swc-website.s3-eu-west-1.amazonaws.com/static_images/deliveries.png',
            description: 'We have multiple full truck deliveries from the UK and EU every week'
        },
        {
            url: 'https://swc-website.s3-eu-west-1.amazonaws.com/static_images/underlay.png',
            description: 'We are a proud stockist of Cloud 9 and Interlfoor underlays'
        },
        {
            url: 'https://swc-website.s3-eu-west-1.amazonaws.com/static_images/containers.png',
            description: 'We also stock our own brand value underlays'
        },

    ];

    paused = false;
    unpauseOnArrow = false;
    pauseOnIndicator = false;
    pauseOnHover = true;

    aboutTestimonial = [
        {
            img: 'assets/images/event/testimonial/L3-1.png',
            location: 'West Sussex',
            text: 'Solent Wholesale are always a pleasure to deal with, having a Wholesaler with a large selection, and a good stock position is key to the running of my flooring business. Just like myself, they are family owned and run, which means I am given the best service whenever I ring!'
        },
        {
            img: 'assets/images/event/testimonial/L3-1.png',
            location: 'Reading',
            text: 'I have been using Solent wholesale for around 8 years now. They are consistently the best place to buy many carpets vinyls and underlay, from jason in the office to Mick my delivery driver they are always great to deal with on day to day basis , I will certainly be using them for many years to come , I am hoping they do another trade day as it was great to put faces to the voices'
        },
        {
            img: 'assets/images/event/testimonial/L3-1.png',
            location: 'Hampshire',
            text: 'In my 15 years trading, Solents are by far the most reliable and professional supplier I have dealt with. All the members of staff , from the office right through to the delivery drivers are always polite and ready to help you in any way they can. You can’t beat a family run business and the trust and relationship my business has built with them over the years is a major part of my own growth. Thank you for all you do and continue to do.'
        }
    ];

    aboutTestimonialCarouselOptions = {
        items: 1,
        margin: 0,
        autoHeight: true,
        nav: true,
        dots: false,
        navText: [
            '<img src="assets/images/music/gallery/gallery-icon/left.png">',
            '<img src="assets/images/music/gallery/gallery-icon/right.png">'],
        autoplay: false,
        slideSpeed: 300,
        paginationSpeed: 400,
        loop: true
    };


    @ViewChild('carousel', {static : true}) carousel: NgbCarousel;

    constructor (private title: Title) { }

    ngOnInit () {
        this.title.setTitle('Solent Wholesale | About Us');
    }

    togglePaused() {
        if (this.paused) {
            this.carousel.cycle();
        } else {
            this.carousel.pause();
        }
        this.paused = !this.paused;
    }

    onSlide(slideEvent: NgbSlideEvent) {
        if (this.unpauseOnArrow && slideEvent.paused &&
            (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
            this.togglePaused();
        }
        if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
            this.togglePaused();
        }
    }




}
