<div>
    <google-map  height="300px" width="100%" [options]="options">
    
    <map-marker *ngFor="let markerPosition of markerPositions"
                [position]="markerPosition"
                [options]="markerOptions"></map-marker>

    </google-map>
</div>

<!-- section start -->
<section class="register-page section-b-space">
  <div class="container">
      <div class="row">
          <div class="col-lg-6">
                  <form class="theme-form">
                    <div class="form-group"  [formGroup]="contactForm">
                      <div class="row">
                          <div class="col-md-12">
                            <h3>Contact us</h3>
                              <p>

                             Checking stock? Placing an order? Have a technical question? Contact the sales office now and we can help you quickly and efficiently.</p> <br />
                          </div>
                      </div>

                      <div class="row">
                          <div class="col-md-12">
                              <label for="email">Your Name</label>
                              <input type="text" class="form-control" id="name"
                                      formControlName="name"
                                      placeholder="Your name">
                                <p *ngIf="contactForm.get(['name']).hasError('required')" 
                                    style="color:red;">A name is required</p>
                                <p *ngIf="contactForm.get(['name']).hasError('maxlength')" 
                                    style="color:red;">You are limited to 100 characters</p>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-md-6">
                              <label for="email">Email</label>
                              <input type="email" class="form-control" id="email"
                                      formControlName="email"
                                      placeholder="Email address">
                                <p *ngIf="contactForm.get(['email']).hasError('required')" 
                                    style="color:red;">An email  is required</p>
                                <p *ngIf="contactForm.get(['email']).hasError('maxlength')" 
                                    style="color:red;">You are limited to 100 characters</p>
                          </div>
                          <div class="col-md-6">
                              <label for="review">Phone No</label>
                              <input type="text" class="form-control" id="number"
                                      formControlName="phoneNumber"
                                      placeholder="Phone number">
                                <p *ngIf="contactForm.get(['phoneNumber']).hasError('required')" 
                                    style="color:red;">A contact number is required</p>
                                <p *ngIf="contactForm.get(['phoneNumber']).hasError('maxlength')" 
                                    style="color:red;">You are limited to 100 characters</p>
                          </div>
                          
                      </div>

                      <div class="row">
                          <div class="col-md-12">
                              <label for="email">Subject</label>
                              <input type="text" class="form-control" id="subject"
                                      formControlName="subject"
                                      placeholder="Message subject">
                                <p *ngIf="contactForm.get(['subject']).hasError('required')" 
                                    style="color:red;">A message subject is required</p>
                                <p *ngIf="contactForm.get(['subject']).hasError('maxlength')" 
                                    style="color:red;">You are limited to 100 characters</p>
                          </div>
                      </div>

                      <div class="row">
                          <div class="col-md-12">
                              <label for="email">Message</label>
                              <input type="textarea" class="form-control" id="message"
                                      formControlName="message"
                                      placeholder="Your Message">
                                <p *ngIf="contactForm.get(['message']).hasError('required')" 
                                    style="color:red;">A message is required</p>
                          </div>
                      </div>

                      <br />
                      <div class="row">
                          <button class="btn primary-btn btn-primary btn-blue radius-0"
                                  [disabled]="contactForm?.invalid"  (click)="submitContact()"  
                          >Submit</button>
                      </div>
                  </div>
                  </form>
            
          </div>

          <div class="col-lg-3 hiddenSMALL">

            <h3>Business Info</h3>
            <h6><fa-icon  class="faContact" [icon]="faMarker"></fa-icon> Address</h6>
            <p> Solent Wholesale Carpets<br />
                Zola Park, Barnfield Dr<br />
                Chichester, W Sussex<br />
                PO19 6UX<br />
            </p><br />
            <h6><fa-icon  class="faContact" [icon]="faEnvelope"></fa-icon>Email</h6>
            <p> <a href="mailto:sales@solentwholesale.com">sales&#64;solentwholesale.com</a></p><br />

            <h6><fa-icon  class="faContact" [icon]="faPhone"></fa-icon>Phone</h6>
            <p> <a href="tel:01243774623">01243 774623</a></p><br />

          </div>

          <div class="col-lg-3 hiddenSMALL">
            
            <h3>New Customer?</h3>
              <p class="highlightURL">

             Are you in the flooring trade? Looking for a new supplier with lots of stock, a great product selection and 
             competitive prices?</p> 
             <br />

             <a [routerLink]="['/page/new-customer']" class="btn btn-default primary-btn info">Open An Account Today</a>

             <br />

            

          </div>
      </div>
  </div>
</section>

<section class="event speaker set-relative" id="speaker">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h3 class="font-primary borders text-center text-uppercase m-b-0">
                            <span >Sales Team</span>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-4 speker-container">
                <div class="text-center">
                    <div class="team-img">
                        <img src="https://swc-website.s3.eu-west-1.amazonaws.com/static_images/rep1.jpeg" alt="" class="img-fluid">
                        <div class="overlay"></div>

                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">Phil Gozzard
                            <fa-icon  class="philFA" [icon]="faCircle"></fa-icon>
                        </h5>
                        <h6 class="post text-center ">07973 406799</h6>
                        <h6 class="post text-center ">Sales Manager - West</h6>
                        <p>Hants, Wiltshire, Dorset, Somerset, IOW, Swindon, Reading, Basingstoke, Oxford, Newbury </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-4 speker-container">
                <div class="text-center">
                    <div class="team-img">
                       <img src="https://swc-website.s3.eu-west-1.amazonaws.com/static_images/rep2.jpeg" alt="" class="img-fluid">
                        <div class="overlay"></div>

                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">Declan Marshall
                            <fa-icon  class="johnFA" [icon]="faCircle"></fa-icon>
                        </h5>
                        <h6 class="post text-center ">07973 898453</h6>
                        <h6 class="post text-center ">Sales Manager - East</h6>
                        <p>E & W Sussex, Surrey, South London, Kent, Milton Keynes, Surrey, North Hampshire, Berkshire, Dunstable</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-4 speker-container">
                <div class="text-center">
                    <div class="team-img">
                       <img src="https://swc-website.s3.eu-west-1.amazonaws.com/static_images/rep3.jpeg" alt="" class="img-fluid">
                        <div class="overlay"></div>

                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">Teague Fullick
                            <fa-icon  class="johnFA" [icon]="faCircle"></fa-icon>
                        </h5>
                        <h6 class="post text-center ">07521 426378</h6>
                        <h6 class="post text-center ">Sales Manager</h6>
                        <p>All areas, new business development.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
