import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-calculators',
    templateUrl: './calculators.component.html',
    styleUrls: ['./calculators.component.scss'],
    standalone: false
})
export class CalculatorsComponent implements OnInit {
    formError;
    unitsReq: number = null;
    largeSheetsReq: number = null;
    smallSheetsReq: number = null;

    sizes = [{name: 25, value: 25}, {name: 20, value: 20}];

    screedForm = new UntypedFormGroup({
        bagSize: new UntypedFormControl(null, [Validators.required, Validators.maxLength(9)]),
        sqm: new UntypedFormControl(null, [Validators.required, Validators.maxLength(100)]),
        thickness: new UntypedFormControl(null, [Validators.required, Validators.maxLength(100)]),
    });

    plywoodForm = new UntypedFormGroup({
        sqm: new UntypedFormControl(null, [Validators.required, Validators.maxLength(100)]),
    });


    constructor() { }

    ngOnInit(): void {
        this.screedForm.valueChanges.subscribe(val => {
            this.calculateScreed();
        });

        this.plywoodForm.valueChanges.subscribe(val => {
            this.calculatePlywood();
        });
    }

    calculatePlywood() {
        this.largeSheetsReq = null;
        this.smallSheetsReq = null;
        const sqm = this.plywoodForm.controls.sqm.value;
        // large sheet 2.972 m2
        // small sheet 0.743 m2
        const largeSheets = sqm / 2.972;
        const smallSheets = sqm / 0.743;
        if (sqm !== null) {
            this.largeSheetsReq = largeSheets;
            this.smallSheetsReq = smallSheets;
        }
 
    }

    calculateScreed() {
        this.unitsReq = null;
        const sqm = this.screedForm.controls.sqm.value;
        const thickness = this.screedForm.controls.thickness.value;
        const bagSize = this.screedForm.controls.bagSize.value;

        const kgRequired = sqm * thickness * 1.39;
        const bagsNett = kgRequired / bagSize;
        if ( bagsNett === 0) {
            this.unitsReq = null;
        } else {
            this.unitsReq = bagsNett;
        }
    }

}
