import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PageService } from '../../_services/index';
import { CustomPage } from '../../_models/index';

@Component({
    selector: 'app-payments',
    templateUrl: './aftercare.component.html',
    styleUrls: ['./aftercare.component.scss'],
    standalone: false
})
export class AftercareComponent implements OnInit {
    selectedPage: CustomPage;
    constructor (
        private title: Title,
        private pageService: PageService) { }

    ngOnInit() {
        this.title.setTitle('Solent Wholesale | Aftercare');
        this.pageService.getCustomPage('care').subscribe(page => {
            this.selectedPage = page;
        });
    }

}
