import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { faMapMarker, faEnvelope, faPhone, faCircle } from '@fortawesome/free-solid-svg-icons';
import { ContactForm } from '../../_models/index';
import { PageService } from '../../_services/index';
import { takeUntil } from 'rxjs/operators';
import { Subject} from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GoogleMap } from '@angular/google-maps';

@Component({
    selector: 'app-payments',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
    standalone: false
})
export class ContactComponent implements OnInit, OnDestroy {
    faMarker = faMapMarker;
    faEnvelope = faEnvelope;
    faPhone = faPhone;
    faCircle = faCircle;
    formError;
    contactForm = new UntypedFormGroup({
        name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(100)]),
        email: new UntypedFormControl(null, [Validators.required, Validators.maxLength(100)]),
        phoneNumber: new UntypedFormControl(null, [Validators.required, Validators.maxLength(100)]),
        subject: new UntypedFormControl(null, [Validators.required, Validators.maxLength(100)]),
        message: new UntypedFormControl(null, [Validators.required])
    });

    options: google.maps.MapOptions = {
        center: {lat: 51.006801, lng: -0.962282},
        zoom: 9,
        streetViewControl: false,
        disableDefaultUI: true,
    };

    markerOptions: google.maps.MarkerOptions = {draggable: false};
    markerPositions: google.maps.LatLngLiteral[] = [{lat: 50.846801, lng: -0.762282}];

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor (
        private title: Title,
        private pageService: PageService,
        private fb: UntypedFormBuilder,
        private router: Router) {
    }


    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }


    ngOnInit() {
        this.title.setTitle('Solent Wholesale | Contact');
    }

    evalAPIResponse (response) {
        if (response.status === true) {
            this.contactForm.reset();
            this.router.navigate(['/']);
        } else {
            this.formError = response.message;
        }

    }

    submitContact() {

        const newMessage: ContactForm = {
            name: this.contactForm.get('name').value,
            email: this.contactForm.get('email').value,
            phone: this.contactForm.get('phoneNumber').value,
            subject: this.contactForm.get('subject').value,
            message: this.contactForm.get('message').value,
        };

        this.pageService.submitContactForm(newMessage).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(response => this.evalAPIResponse(response));
    }

}
