<!-- section start -->
<section class="register-page section-b-space">
  <div class="container">
      <div class="row">
          <div class="col-lg-10">

                  <form class="theme-form">
                    <div class="form-group"  [formGroup]="newCustomerForm">

                      <div class="row">
                          <div class="col-md-12">
                            <h3>New Customer - Account Application</h3>
                              <p>
                              Looking for an efficient and competitively priced new wholesaler? Well you found the right place! Fill
                              out the form below and we can proceed to open your new Solent Wholesale trade account.
                             Please note we are <span class="bold"> strictly trade only</span> and all applications
                             are subject to vetting before being opened.</p> <br />
                          </div>
                      </div>

                      <div class="row">
                          <div class="col-md-6">
                              <label for="email">Your Name</label>
                              <input type="text" class="form-control" id="name"
                                      formControlName="name"
                                      placeholder="Your Name">
                              <p *ngIf="newCustomerForm.get(['name']).hasError('required')" 
                                    class="form-warning">A name is required</p>
                              <p *ngIf="newCustomerForm.get(['name']).hasError('maxlength')" 
                                  class="form-warning">You are limited to 100 characters</p>
                          </div>

                          <div class="col-md-6">
                              <label for="email">Business/Company Name</label>
                              <input type="text" class="form-control" id="tname"
                                      formControlName="tradingName"
                                      placeholder="Trading name">
                               <p *ngIf="newCustomerForm.get(['tradingName']).hasError('required')" 
                                    class="form-warning">Trading name is required</p>
                              <p *ngIf="newCustomerForm.get(['tradingName']).hasError('maxlength')" 
                                  class="form-warning">You are limited to 150 characters</p>
                          </div>
                      </div>

                      <div class="row">
                          <div class="col-md-6">
                              <label for="email">Main Contact Number </label>
                              <input type="text" class="form-control" id="landline"
                                      formControlName="landline"
                                      placeholder="Main phone number">
                              <p *ngIf="newCustomerForm.get(['landline']).hasError('required')" 
                                    class="form-warning">A phone number is required</p>
                              <p *ngIf="newCustomerForm.get(['landline']).hasError('maxlength')" 
                                  class="form-warning">You are limited to 100 characters</p>
                          </div>

                          <div class="col-md-6">
                              <label for="email">Mobile</label>
                              <input type="text" class="form-control" id="mobile"
                                      formControlName="mobile"
                                      placeholder="Mobile phone number">
                              <p *ngIf="newCustomerForm.get(['mobile']).hasError('maxlength')" 
                                  class="form-warning">You are limited to 100 characters</p>
                          </div>
                      </div>

                      <div class="row">
                          <div class="col-md-12">
                              <label for="email">Email</label>
                              <input type="email" class="form-control" id="email"
                                      formControlName="email"
                                      placeholder="Email Address">
                               <p *ngIf="newCustomerForm.get(['email']).hasError('required')" 
                                    class="form-warning">An email required</p>
                              <p *ngIf="newCustomerForm.get(['email']).hasError('maxlength')" 
                                  class="form-warning">You are limited to 200 characters</p>
                          </div>
                      </div>

                      <div class="row">
                          <div class="col-md-6">
                              <label for="email">Trading/Invoice Address</label>
                              <textarea rows="6" class="form-control" id="invAddress"
                                      formControlName="invAddress"
                                      placeholder="Trading Address">
                              </textarea>
                              <p *ngIf="newCustomerForm.get(['invAddress']).hasError('required')" 
                                    class="form-warning">Address required</p>

                          </div>

                          <div class="col-md-6">
                              <label for="email">Delivery Address (if different)</label>
                              <textarea rows="6" class="form-control" id="delAddress"
                                      formControlName="delAddress"
                                      placeholder="Delivery Address">
                              </textarea>
                          </div>

                      </div>

                      <div class="row">
                          <div class="col-md-6">
                              <label for="email">Opening/Closing Times</label>
                              <input type="text" class="form-control" id="times"
                                      formControlName="times"
                                      placeholder="E.g 9-5 or 'Anytime' ">
                              <p *ngIf="newCustomerForm.get(['times']).hasError('maxlength')" 
                                  class="form-warning">You are limited to 100 characters</p>
                          </div>

                          <div class="col-md-6">
                              <label for="email">Special Instructions</label>
                              <input type="text" class="form-control" id="special"
                                      formControlName="special"
                                      placeholder="E.g keysafe code 1234">
                              <p *ngIf="newCustomerForm.get(['special']).hasError('maxlength')" 
                                  class="form-warning">You are limited to 100 characters</p>
                          </div>
                      </div>

                      <div class="row">
                          <div class="col-md-6">
                              <label for="email">Home Address (if different)</label>
                              <textarea rows="6" class="form-control" id="homeAddress"
                                      formControlName="homeAddress"
                                      placeholder="Home Address">
                              </textarea>
                          </div>

                          <div class="col-md-6">
                            <h6 class="paddingTop"> ** Not be applicable to all applications ** </h6>
                              <label for="email">Company Reg #</label>
                              <input type="text" class="form-control" id="companyReg"
                                      formControlName="companyReg"
                                      placeholder="Company registration number">
                              <p *ngIf="newCustomerForm.get(['companyReg']).hasError('maxlength')" 
                                  class="form-warning">You are limited to 100 characters</p>
                              <br />
                              <label for="email">Vat # </label>
                              <input type="text" class="form-control" id="vatno"
                                      formControlName="vatNo"
                                      placeholder="VAT Number">
                              <p *ngIf="newCustomerForm.get(['vatNo']).hasError('maxlength')" 
                                  class="form-warning">You are limited to 100 characters</p>
                          </div>


                      </div>

                      <div class="row">
                          <div class="col-md-6">
                              <label for="email">Current Suppliers</label>
                              <textarea rows="4" class="form-control" id="currentSuppliers"
                                formControlName="currentSuppliers"
                                placeholder="Please list any current suppliers from the flooring trade (if any)">
                              </textarea>

                              <p *ngIf="newCustomerForm.get(['currentSuppliers']).hasError('required')" 
                                    class="form-warning">Please list current suppliers</p>
                          </div>

                          <div class="col-md-6">
                              <label for="email">Credit Account</label>
                          
                              <select class="form-control"
                                    formControlName="credit">
                              <option selected="selected" value="No"> NO  </option>
                              <option value="Yes"> YES  </option>
                              </select>

                              <h6 class="paddingTop"> If you require a credit account, 
                              please let us know, we will email a document that needs
                              signing before we can take credit references on your behalf </h6>

                          </div>
                      </div>

                      <div class="row">
                          <div class="col-1">
                              <input id="terms" class="form-check-input" type="checkbox" formControlName="terms">
                          </div>
                          <div class="col-11">
                              <h6 class="form-accept"> I have read and accept the 
                                  <a class="privacy-link" [routerLink]="['/page/content', 'terms']" >
                                      terms of trading</a> 
                                </h6>
                              <p><span *ngIf="newCustomerForm.get(['terms']).hasError('required')" 
                                    class="form-warning">You must accept our terms of trading</span>&nbsp; </p>
                          </div>
                      </div>

                      <div class="row">
                        <div class="col-1">
                            <input id="privacy" class="form-check-input" type="checkbox" formControlName="privacy">
                        </div>
                        <div class="col-11">
                            <h6 class="form-accept"> I accept the  <a [routerLink]="['/page/content', 'privacy']" 
                                class="privacy-link"> privacy policy </a>
                            </h6>
                            <p><span *ngIf="newCustomerForm.get(['privacy']).hasError('required')" 
                                class="form-warning">You must accept our terms of trading</span>&nbsp; </p>
                        </div>
                    </div>


                      <div class="row">
                          <button [disabled]="newCustomerForm?.invalid" 
                                  (click)="submitForm()"
                                  class="btn primary-btn btn-primary btn-blue radius-0">Submit</button>
                      </div>
                  </div>
                  </form>

          </div>


          <div class="col-lg-2 hiddenSMALL">
            <h3>Looking for Solent Express?</h3>
              <p class="highlightURL">
                    Solent Express is a national service we offer on 
                    <a href="" class="solentlink">selected products</a>, available nationwide
                    with next day delivery. Exclusive to the flooring trade, 
                    <a [routerLink]="['/new-customer']" class="solentlink">open an account</a> today.
              </p> 
              <br />
          </div>

      </div>
  </div>
</section>