import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NewCustomerForm } from '../../_models/index';
import { PageService } from '../../_services/index';
import { takeUntil } from 'rxjs/operators';
import { Subject} from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-newcustomer',
    templateUrl: './newcustomer.component.html',
    styleUrls: ['./newcustomer.component.scss'],
    standalone: false
})
export class NewCustomerComponent implements OnInit, OnDestroy {
    formError;
    newCustomerForm = new UntypedFormGroup({
        name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(100)]),
        tradingName: new UntypedFormControl(null, [Validators.required, Validators.maxLength(150)]),
        landline: new UntypedFormControl(null, [Validators.required, Validators.maxLength(100)]),
        mobile: new UntypedFormControl(null, [Validators.maxLength(100)]),
        email: new UntypedFormControl(null, [Validators.required, Validators.maxLength(200)]),
        invAddress: new UntypedFormControl(null, [Validators.required]),
        delAddress: new UntypedFormControl(null),
        times: new UntypedFormControl(null, Validators.maxLength(100)),
        special: new UntypedFormControl(null, Validators.maxLength(100)),
        homeAddress: new UntypedFormControl(null),
        companyReg: new UntypedFormControl(null, Validators.maxLength(100)),
        vatNo: new UntypedFormControl(null, Validators.maxLength(100)),
        currentSuppliers: new UntypedFormControl(null, [Validators.required]),
        credit: new UntypedFormControl('No'),
        terms: new UntypedFormControl(null, [Validators.requiredTrue]),
        privacy: new UntypedFormControl(null, [Validators.requiredTrue]),
    });

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor (
        private title: Title,
        private pageService: PageService,
        private router: Router) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit () {
        this.title.setTitle('Solent Wholesale | New Customer');
    }

    evalAPIResponse (response) {
        if (response.status === true) {
            this.newCustomerForm.reset();
            this.router.navigate(['/']);
        } else {
            this.formError = response.message;
        }

    }

    submitForm() {

        const newMessage: NewCustomerForm = {
            name: this.newCustomerForm.get('name').value,
            tradingName: this.newCustomerForm.get('tradingName').value,
            landline: this.newCustomerForm.get('landline').value,
            mobile: this.newCustomerForm.get('mobile').value,
            email: this.newCustomerForm.get('email').value,
            invAddress: this.newCustomerForm.get('invAddress').value,
            delAddress: this.newCustomerForm.get('delAddress').value,
            times: this.newCustomerForm.get('times').value,
            special: this.newCustomerForm.get('special').value,
            homeAddress: this.newCustomerForm.get('homeAddress').value,
            companyReg: this.newCustomerForm.get('companyReg').value,
            vatNo: this.newCustomerForm.get('vatNo').value,
            currentSuppliers: this.newCustomerForm.get('currentSuppliers').value,
            credit: this.newCustomerForm.get('credit').value,
            terms: this.newCustomerForm.get('terms').value,
            privacy: this.newCustomerForm.get('privacy').value,
        };

        this.pageService.submitCustomerForm(newMessage).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(response => this.evalAPIResponse(response));
    }



}
